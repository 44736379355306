<template>
  <div>
    <NavigationBar
      titel="Details"
      :actions="actions"
      showBackBtn
    ></NavigationBar>
    <section>
      <template>
        <v-container>
          <v-row justify="center" align="center">
            <v-col cols="12">
              <Subheader icon="mdi-card-text" title="Informationen"></Subheader>
              <div class="mt-7">
                <rank-detail-card :rank="data"></rank-detail-card>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section>
    <section v-if="checkIfSupport">
      <support-tools :sources="code"></support-tools>
    </section>
    <section>
      <custom-dialog
        v-if="helpers.rankImageUpload.dialogModel"
        @close="toggleRankImageUploadDialog()"
        title="Grafik für Dienstgrad festlegen"
        width="m"
        :errorMessage="helpers.rankImageUpload.errorMessage"
        :btnAction="uploadRankImage"
        :btnDisabled="!helpers.rankImageUpload.formIsValid"
        :loading="helpers.rankImageUpload.loading"
        btnText="Grafik hochladen"
      >
        <template v-slot:content>
          <v-form
            v-model="helpers.rankImageUpload.formIsValid"
            @submit.prevent="uploadRankImage()"
          >
            <v-row>
              <v-col cols="12" sm="12" md="6">
                <v-file-input
                  v-model="rankFile"
                  :rules="[rules.maxFileSize, rules.fileType, rules.required]"
                  outlined
                  hide-details="auto"
                  chips
                  show-size
                  clearable
                  accept="image/png, image/jpeg"
                  label="Neue Datei auswählen"
                  prepend-inner-icon="mdi-image"
                  prepend-icon=""
                  type="file"
                  @click:clear="fileCleared()"
                  @change="generateFilePreviewUrl()"
                >
                </v-file-input>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-card v-if="filePreviewUrl" outlined>
                  <v-card-subtitle>Vorschau</v-card-subtitle>
                  <v-card-text
                    ><v-img
                      :src="filePreviewUrl"
                      contain
                      height="180px"
                      class="mb-2"
                    ></v-img
                  ></v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-form>
        </template>
      </custom-dialog>
      <v-snackbar
        v-model="helpers.rankImageUpload.snackbar.model"
        timeout="5000"
        >{{ helpers.rankImageUpload.snackbar.text }}</v-snackbar
      >
    </section>
  </div>
</template>

<script>
import { db, storage, TaskEvent } from "@/firebase";
import Subheader from "@/components/_systemwide/Subheader.vue";
import CustomDialog from "@/components/_systemwide/CustomDialog.vue";
import RankDetailCard from "@/components/member-management/ranks/RankDetailCard.vue";
import SupportTools from "@/components/_system/helpers/SupportTools.vue";
import { MEMBER_MANAGEMENT } from "@/store/modules.js";
import { DELETE_RANK } from "@/store/action-types.js";
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
import {
  MEMBER_MANAGEMENT_RANKS_update,
  MEMBER_MANAGEMENT_RANKS_delete,
} from "@/data/permission-types.js";

export default {
  name: "member-management-config-ranks-details",
  components: {
    NavigationBar,
    CustomDialog,
    SupportTools,
    Subheader,
    RankDetailCard,
  },
  data() {
    return {
      actions: [
        {
          title: "Bearbeiten",
          permissions: `${MEMBER_MANAGEMENT_RANKS_update}`,
          icon: "mdi-pencil",
          actionStyle: "textBtn",
          function: () => {
            this.$router.push(
              { name: "member-management-config-ranks-edit" },
              {
                organizationId: this.$route.params.organizationId,
                itemId: this.$route.params.itemId,
              }
            );
          },
        },
        {
          title: "Aktionen",
          icon: "mdi-dots-horizontal-circle",
          actionStyle: "textBtnMultiple",
          list: [
            {
              title: "Grafik für Dienstgrad hochladen",
              permissions: `${MEMBER_MANAGEMENT_RANKS_update}`,
              icon: "mdi-file-image-plus",
              function: this.toggleRankImageUploadDialog,
            },
            {
              title: "Löschen",
              permissions: `${MEMBER_MANAGEMENT_RANKS_delete}`,
              icon: "mdi-delete",
              function: this.deleteItem,
            },
          ],
        },
        { divider: true },
        { actionStyle: "clipboardBtn" },
      ],

      data: null,
      helpers: {
        rankImageUpload: {
          dialogModel: false,
          formIsValid: false,
          loading: false,
          errorMessage: "",
          snackbar: {
            model: false,
            message: "",
          },
        },
      },
      rankFile: null,
      filePreviewUrl: "",
      rules: {
        required: (value) => !!value || "Datei ist erforderlich.",
        maxFileSize: (value) =>
          !value ||
          value.size < 2000000 ||
          "Dateigröße muss kleiner als 2 MB sein.",
        fileType: (value) => {
          if (!value) return true; // If no file is selected, it passes validation
          const allowedTypes = ["image/jpeg", "image/png"];
          return (
            allowedTypes.includes(value.type) ||
            "Datei muss vom Typ JPEG oder PNG sein."
          );
        },
      },
    };
  },
  computed: {
    checkIfSupport() {
      return this.$store.getters["organization/checkIfSupport"];
    },
    code() {
      return [{ title: "rank", data: this.data }];
    },
  },
  created() {
    this.initialize();
  },
  watch: {
    $route: "initialize",
  },
  methods: {
    initialize() {
      db.collection("organizations")
        .doc(this.$route.params.organizationId)
        .collection("ranks")
        .doc(this.$route.params.itemId)
        .get()
        .then((doc) => {
          if (doc.exists) {
            this.data = doc.data();
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
          return false;
        });
    },
    deleteItem() {
      var res = confirm(
        "Bist Du sicher, dass Du dieses Dienstgrad löschen möchtest?"
      );
      if (res == true) {
        this.$store.dispatch(`${MEMBER_MANAGEMENT}${DELETE_RANK}`, {
          organizationId: this.$route.params.organizationId,
          itemId: this.$route.params.itemId,
        });
      }
    },
    toggleRankImageUploadDialog() {
      this.filePreviewUrl = "";
      this.rankFile = null;
      this.helpers.rankImageUpload.dialogModel =
        !this.helpers.rankImageUpload.dialogModel;
    },
    generateFilePreviewUrl() {
      // Generate a preview url that I can use to display the uploaded image in "src" of v-img
      this.filePreviewUrl = URL.createObjectURL(this.rankFile);
    },
    uploadRankImage() {
      this.helpers.rankImageUpload.errorMessage = "";
      this.helpers.rankImageUpload.loading = true;
      // Create the file metadata
      var file = this.rankFile;
      var metadata = {
        contentType: "image/png",
      };

      var storageRef = storage.ref();
      // Upload file and metadata to the object 'images/mountains.jpg'
      var uploadTask = storageRef
        .child("organizations/")
        .child(this.$route.params.organizationId + "/")
        .child("ranks/" + this.$route.params.itemId + "/rank.png")
        .put(file, metadata);

      // Listen for state changes, errors, and completion of the upload.

      uploadTask.on(
        TaskEvent.STATE_CHANGED, // or 'state_changed'
        (snapshot) => {
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          // var progress =
          //   (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          switch (snapshot.state) {
            case TaskEvent.PAUSED: // or 'paused'
              console.log("Upload is paused");
              break;
            case TaskEvent.RUNNING: // or 'running'
              console.log("Upload is running");
              break;
          }
        },
        (error) => {
          this.helpers.rankImageUpload.loading = false;
          this.helpers.rankImageUpload.errorMessage = error;
          return console.log(error);
        },
        () => {
          // Upload completed successfully, now we can get the download URL
          this.helpers.rankImageUpload.loading = false;
          this.toggleRankImageUploadDialog();
          this.helpers.rankImageUpload.snackbar.model = true;
          this.helpers.rankImageUpload.snackbar.text =
            "Grafik für Dienstgrad erfolgreich aktualisiert. Es kann einige Minuten dauern, bis die Änderung aktiv wird.";
          // uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          //   console.log("File available at", downloadURL);
          // });
        }
      );
    },
  },
};
</script>

<style></style>
