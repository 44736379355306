export const importableRanks = [
  {
    title: "Feuerwehrmann-Anwärter",
    shortTitle: "FMA",
    isActive: true,
    sortKey: 1,
    state: "Berlin",
    badgeUrl: {
      size70x70: null,
      size125x125: null,
    },
    isImported: true,
    importVersion: 1,
    importId: "bln.fma",
  },
  {
    title: "Feuerwehrmann-Anwärterin",
    shortTitle: "FFA",
    isActive: true,
    sortKey: 1,
    state: "Berlin",
    badgeUrl: {
      size70x70: null,
      size125x125: null,
    },
    isImported: true,
    importVersion: 1,
    importId: "bln.ffa",
  },

  {
    title: "Feuerwehrmann",
    shortTitle: "FM",
    isActive: true,
    sortKey: 2,
    state: "Berlin",
    badgeUrl: {
      size70x70: null,
      size125x125: null,
    },
    isImported: true,
    importVersion: 1,
    importId: "bln.fm",
  },
  {
    title: "Feuerwehrfrau",
    shortTitle: "FFR",
    isActive: true,
    sortKey: 2,
    state: "Berlin",
    badgeUrl: {
      size70x70: null,
      size125x125: null,
    },
    isImported: true,
    importVersion: 1,
    importId: "bln.ffr",
  },

  {
    title: "Oberfeuerwehrmann",
    shortTitle: "OFM",
    isActive: true,
    sortKey: 3,
    state: "Berlin",
    badgeUrl: {
      size70x70: null,
      size125x125: null,
    },
    isImported: true,
    importVersion: 1,
    importId: "bln.ofm",
  },
  {
    title: "Oberfeuerwehrfrau",
    shortTitle: "OFF",
    isActive: true,
    sortKey: 3,
    state: "Berlin",
    badgeUrl: {
      size70x70: null,
      size125x125: null,
    },
    isImported: true,
    importVersion: 1,
    importId: "bln.off",
  },

  {
    title: "Brandmeister",
    shortTitle: "BM",
    isActive: true,
    sortKey: 4,
    state: "Berlin",
    badgeUrl: {
      size70x70: null,
      size125x125: null,
    },
    isImported: true,
    importVersion: 1,
    importId: "bln.bm",
  },
  {
    title: "Brandmeisterin",
    shortTitle: "BM",
    isActive: true,
    sortKey: 4,
    state: "Berlin",
    badgeUrl: {
      size70x70: null,
      size125x125: null,
    },
    isImported: true,
    importVersion: 1,
    importId: "bln.bmin",
  },

  {
    title: "Oberbrandmeister",
    shortTitle: "OBM",
    isActive: true,
    sortKey: 5,
    state: "Berlin",
    badgeUrl: {
      size70x70: null,
      size125x125: null,
    },
    isImported: true,
    importVersion: 1,
    importId: "bln.obm",
  },
  {
    title: "Oberbrandmeisterin",
    shortTitle: "OBM",
    isActive: true,
    sortKey: 5,
    state: "Berlin",
    badgeUrl: {
      size70x70: null,
      size125x125: null,
    },
    isImported: true,
    importVersion: 1,
    importId: "bln.obmin",
  },

  {
    title: "Hauptbrandmeister",
    shortTitle: "HBM",
    isActive: true,
    sortKey: 6,
    state: "Berlin",
    badgeUrl: {
      size70x70: null,
      size125x125: null,
    },
    isImported: true,
    importVersion: 1,
    importId: "bln.hbm",
  },
  {
    title: "Hauptbrandmeisterin",
    shortTitle: "HBM",
    isActive: true,
    sortKey: 6,
    state: "Berlin",
    badgeUrl: {
      size70x70: null,
      size125x125: null,
    },
    isImported: true,
    importVersion: 1,
    importId: "bln.hbmin",
  },

  {
    title: "Erster Hauptbrandmeister",
    shortTitle: "1HBM",
    isActive: true,
    sortKey: 7,
    state: "Berlin",
    badgeUrl: {
      size70x70: null,
      size125x125: null,
    },
    isImported: true,
    importVersion: 1,
    importId: "bln.1hbm",
  },
  {
    title: "Erste Hauptbrandmeisterin",
    shortTitle: "1HBM",
    isActive: true,
    sortKey: 7,
    state: "Berlin",
    badgeUrl: {
      size70x70: null,
      size125x125: null,
    },
    isImported: true,
    importVersion: 1,
    importId: "bln.1hbmin",
  },

  {
    title: "Brandinspektor",
    shortTitle: "BI",
    isActive: true,
    sortKey: 8,
    state: "Berlin",
    badgeUrl: {
      size70x70: null,
      size125x125: null,
    },
    isImported: true,
    importVersion: 1,
    importId: "bln.bi",
  },
  {
    title: "Brandinspektorin",
    shortTitle: "BI",
    isActive: true,
    sortKey: 8,
    state: "Berlin",
    badgeUrl: {
      size70x70: null,
      size125x125: null,
    },
    isImported: true,
    importVersion: 1,
    importId: "bln.biin",
  },

  {
    title: "Brandoberinspektor",
    shortTitle: "BOI",
    isActive: true,
    sortKey: 9,
    state: "Berlin",
    badgeUrl: {
      size70x70: null,
      size125x125: null,
    },
    isImported: true,
    importVersion: 1,
    importId: "bln.boi",
  },
  {
    title: "Brandoberinspektorin",
    shortTitle: "BOI",
    isActive: true,
    sortKey: 9,
    state: "Berlin",
    badgeUrl: {
      size70x70: null,
      size125x125: null,
    },
    isImported: true,
    importVersion: 1,
    importId: "bln.boiin",
  },
];
